/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  font-size: 18px;
}
.text-filter-dropdown,
.date-filter-dropdown,
.daterange-filter-dropdown,
.boolean-radio-filter-dropdown,
.boolean-checkbox-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  display: flex;
}
.text-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}
.date-filter-dropdown .ant-calendar-picker-input {
  width: 278px;
  margin-right: 8px;
}
.daterange-filter-dropdown .ant-calendar-picker-input {
  width: 550px;
  margin-right: 8px;
}
.ant-table-wrapper table {
  background-color: #fff;
}
.ant-table-small {
  border: none;
}
.ant-table-small > .ant-table-container > .ant-table-content > .ant-table-body {
  margin: 0;
}
.ant-table-small tr > th:first-child {
  padding-left: 22px !important;
}
.ant-table-small tr > td:first-child {
  padding-left: 30px !important;
}
.ant-table-small tr > th:last-child {
  padding-right: 22px !important;
}
.ant-table-small tr > td:last-child {
  padding-right: 30px !important;
}
.datatable-header {
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: row;
}
.datatable-header .ant-typography {
  flex: 1;
  margin-bottom: 0;
  margin-right: 20px;
}
.datatable-header .generic-filter {
  margin-right: 20px;
  max-width: 500px;
}
.dataTable-button-bar {
  display: flex;
}
.dataTable-button-bar .ant-btn:not(:last-child) {
  margin-right: 10px;
}
.datatable.datatable-bordered .ant-table {
  border: 1px solid #edf0f4;
}
.datatable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #edf0f4;
}
.datatable td.editable-cell {
  padding: 2px 3px !important;
}
.datatable.datatable-compact .ant-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}
.datatable.datatable-compact .ant-table-column-sorter {
  margin-left: 0;
}
.datatable.cursor-hand tbody tr td {
  cursor: pointer;
}
.datatable .ant-table-column-sorters {
  width: unset;
  padding: 0;
}
.datatable .ant-table-filter-trigger-container {
  top: calc(50% + 4px);
  bottom: unset;
  right: -6px;
}
.datatable.datatable-alternating-row .ant-table .ant-table-thead > tr > th {
  border-top: 1px solid #edf0f4;
  border-bottom: 2px solid #edf0f4 !important;
}
.datatable.datatable-alternating-row .ant-table .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f8f8f8;
}
.datatable.datatable-alternating-row .ant-table tr:nth-child(even) {
  background: #f7f9fc;
}
.datatable.datatable-alternating-row .ant-table tr:nth-child(odd) {
  background: #fff;
}
.datatable.datatable-compact .ant-table {
  font-size: 12px !important;
}
.datatable.datatable-compact .ant-table .ant-table-thead > tr > th {
  padding: 6px !important;
}
.datatable.datatable-compact .ant-table .ant-table-thead > tr > th.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.datatable.datatable-compact .ant-table .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  margin-left: 0.25em;
}
.datatable.datatable-compact .ant-table .ant-input,
.datatable.datatable-compact .ant-table .ant-input-number-input {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-title,
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-footer,
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-thead > tr > th,
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-tbody > tr > td,
.datatable.datatable-compact .ant-table.ant-table-small tfoot > tr > th,
.datatable.datatable-compact .ant-table.ant-table-small tfoot > tr > td {
  padding: 4px 4px;
}
.datatable.datatable-compact .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
}
.datatable.datatable-compact .ant-table-small tr > th:first-child,
.datatable.datatable-compact .ant-table-small tr > td:first-child {
  padding-left: 12px !important;
}
.datatable.datatable-compact .ant-table-small tr > th:last-child,
.datatable.datatable-compact .ant-table-small tr > td:last-child {
  padding-right: 12px !important;
}
i.link-button {
  font-size: 24px;
}
.dtSumRow > td {
  border-top: 2px solid #e8e8e8;
}
