@import '~antd/es/style/themes/default.less';
@import '~antd/es/style/mixins/index.less';

td {
  white-space: pre;
}

.ant-space-vertical {
  width: 100%;
}

.ant-space-horizontal {
  flex-wrap: wrap;
}

.app-spinner {
  .ant-spin {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 29px);
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

//Breadcrumb
.ant-page-header > .ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.66) !important;
}

//Active Step
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.66) !important;
}

//Step Description
.ant-steps-item-description {
  padding: 10px;
}

//Waiting Steps
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.66) !important;
}

//Subtitle
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.66) !important;
}

// Subtitle Step
.ant-steps-item-subtitle{
  color: rgba(0, 0, 0, 0.66) !important;
}

// Error Steps
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
  color: #EB0004 !important
}

.ant-error-message{
  color: #EB0004 !important
}

.ant-statistic-title{
  color: rgba(0, 0, 0, 0.66) !important;
}

// Yes/No Input
.ant-form-item-has-error {
  .yes-no-input {
    button {
      border-color: @alert-error-border-color !important;
      background-color: @alert-error-bg-color;
    }
  }
}

// Descriptions
.ant-descriptions-item-label {
  font-weight: bold;
  span {
    font-weight: bold;
  }
}

// Layouts
.ant-layout-header {
  padding: 0px;
}

.width-small,
.width-default,
.width-wide,
.width-full {
  width: 100%;
  padding: 15px;

  @media (max-width: 575px) {
    padding: 0;
  }
}

.breadcrumb-area {
  background-color: white;
  width: 100%;
}

.width-fit-content {
  width: min-content;
}

//Min Max Input
.ant-input-affix-wrapper.ant-input-affix-wrapper.min-max-input {
  width: 100px;
  text-align: right;
}

// Cards
.sub-inner-card {
  background-color: #fcfcfc !important;
}

.ant-card.card-success {
  border-color: @alert-success-border-color;
  .ant-card-head {
    background-color: @alert-success-bg-color;
    border-color: @alert-success-border-color;
  }
}

.ant-card.card-warning {
  border-color: @alert-warning-border-color;
  .ant-card-head {
    background-color: @alert-warning-bg-color;
    border-color: @alert-warning-border-color;
  }
}

.ant-card.card-danger {
  border-color: @alert-error-border-color;
  .ant-card-head {
    background-color: @alert-error-bg-color;
    border-color: @alert-error-border-color;
  }
}

// Header
.header-icon {
  color: white;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;

  svg {
    width: 18px;
    height: 18px;
  }
}

.ant-page-header.subHeader {
  padding: 0%;
  background-color: lightgray;
}

.ant-page-header-heading-sub-title{
  color: rgba(0, 0, 0, 0.66) !important;
}

// Forms
.ant-input-prefix {
  margin-right: 10px !important;
}

.ant-select{
  width:100%
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  align-self: start;
  margin-top: 2px;
}

.ant-form-item-extra {
  color: rgba(0, 0, 0, 0.66) !important;
}

// General
.align-center {
  align-items: center;
  text-align: center;
  align-self: center;
  align-content: center;
}

// Steps
.antd_steps_nested {
  margin-top: 15px !important;
}

// Inputs
.ant-input-prefix,
.ant-input-suffix {
  color: @idoe-gray;
}

td {
  > .ant-form-item {
    margin-bottom: 0px;
  }
}

//Buttons
.btn-left-align {
  width: 100%;
  text-align: left;
}

.ant-btn-link-light {
  color: White;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.btn-white-link {
  color: White;
  :hover {
    color: @idoe-blue;
  }
}

.ant-btn-dangerous{
  color: #EB0004;
}

.ant-btn-dangerous.ant-btn-primary:hover, .ant-btn-dangerous.ant-btn-primary:focus,.ant-btn-dangerous.ant-btn-primary {
  background:#EB0004 !important;
  border-color:#EB0004 !important;
}

// Tabs
.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.customDraggableTab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab.customDraggableTab{
  display: flex;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab{
  display: flex;
}

.ant-form-item.description-container{
  margin-bottom: 0px;
}

.footer-space {
  float: right;
  }

//Rich Text Editor
.bf-container{
    border-color:#d9d9d9;
    border-style: solid;
    border-width: 1px;
}

//Tables
.table-span-light {
  width: 15px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -10px;
  margin-bottom: -10px;
  background-color: @idoe-gray;
}

.table-span-dark {
  width: 15px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -10px;
  margin-bottom: -10px;
  background-color: @idoe-blue;
}

.table-row-light {
  background-color: @idoe-gray;
}

.table-row-dark {
  background-color: @app-dark-gray;
}

.table-row-primary {
  background-color: @idoe-dark-blue;
}

.table-row-secondary {
  background-color: @idoe-blue;
}

.table-row-primary-light {
  background-color: @idoe-lighter-blue;
}

.table-half-size{
  width: 50%
}

/////////////////////////////////////////////////
// ---------- Device Size Overrides ---------- //
/////////////////////////////////////////////////

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .column {
    flex: 50% !important; /* !important so that it overrides the inline style because by default it has higher priority */
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .header-title {
    display: none;
  }

  .content-main,
  .content-pad {
    padding: 12px;
  }

  .content-head {
    padding: 12px 15px;
  }

  .content-container {
    padding: 12px;
  }

  .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 0 12px 12px;
  }

  .ant-layout-sider.ant-layout-sider-collapsed {
    min-width: 0 !important;
    max-width: 0 !important;
    width: 0 !important;
    flex: 0 !important;
  }

  .ant-layout-sider.ant-layout-sider-collapsed li.ant-menu-item,
  .ant-layout-sider.ant-layout-sider-collapsed li.ant-menu-submenu {
    display: none;
  }

  .ant-modal {
    width: 95% !important;
  }

  .layout-fullscreen {
    > .ant-layout-sider {
      > .ant-layout-sider-children {
        > .logo-container {
          width: 195px;
        }

        .scrollbar-sidenav {
          top: 48px !important;
        }

        > .ant-menu {
          top: 48px;
        }
      }
    }

    > .ant-layout {
      > .ant-layout-header {
        left: 195px;
      }

      > .ant-layout-content.page-content {
        left: calc(195px + 12px);
        right: 12px;
        top: calc(48px + 12px);
        bottom: 12px;

        > .ant-layout.content-container {
          > .ant-layout-content,
          > .ant-spin-nested-loading > .ant-spin-container > .ant-layout-content {
            max-height: calc(100vh - 105px + 17px + 10px);
          }
        }
      }
    }

    > .ant-layout-sider-collapsed {
      + .ant-layout {
        > .ant-layout-header {
          left: 0;
        }

        > .ant-layout-content {
          left: 12px;
        }
      }
    }
  }

  .column {
    flex: 100% !important; /* !important so that it overrides the inline style because by default it has higher priority */
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 605px) {
  .content-main,
  .content-pad {
    padding: 6px;
  }

  .content-head {
    padding: 6px 8px;
  }

  .content-container {
    padding: 12px 6px 6px 6px;
  }

  .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 0 6px 6px;
  }

  .column {
    flex: 100% !important; /* !important so that it overrides the inline style because by default it has higher priority */
  }
}

// Custom
.academic-year-overview {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
@idoe-dark-blue: #002A4B;@idoe-blue: #2A5192;@idoe-light-blue: #C5D1EB;@idoe-lighter-blue: #E8EFFC;@idoe-yellow: #FFE363;@idoe-gray: #A5A5A5;@idoe-orange: #DC6400;@idoe-yellow-two: #FFC200;@app-dark-gray: #3C3C3C;@app-light-gray: #F6F6F6;@primary-color: @idoe-blue;@layout-body-background: @app-light-gray;@layout-header-background: @idoe-dark-blue;@layout-sider-background: @app-dark-gray;@info-color: @idoe-light-blue;@processing-color: @idoe-yellow;@highlight-color: @idoe-orange;@warning-color: @idoe-yellow-two;@menu-dark-bg: @app-dark-gray;@form-error-input-bg: @alert-error-bg-color;@form-warning-input-bg: @alert-warning-bg-color;@disabled-color: @text-color;@disabled-bg: @app-light-gray;@disabled-color-dark: fade(#fff, 35%);@tooltip-bg: @app-dark-gray;@breadcrumb-link-color: 0,0,0,.66;