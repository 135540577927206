/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
td {
  white-space: pre;
}
.ant-space-vertical {
  width: 100%;
}
.ant-space-horizontal {
  flex-wrap: wrap;
}
.app-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.app-spinner .ant-spin {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 29px);
}
.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}
.ant-page-header > .ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.66) !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.66) !important;
}
.ant-steps-item-description {
  padding: 10px;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.66) !important;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.66) !important;
}
.ant-steps-item-subtitle {
  color: rgba(0, 0, 0, 0.66) !important;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #EB0004 !important;
}
.ant-error-message {
  color: #EB0004 !important;
}
.ant-statistic-title {
  color: rgba(0, 0, 0, 0.66) !important;
}
.ant-form-item-has-error .yes-no-input button {
  border-color: #ffccc7 !important;
  background-color: #fff2f0;
}
.ant-descriptions-item-label {
  font-weight: bold;
}
.ant-descriptions-item-label span {
  font-weight: bold;
}
.ant-layout-header {
  padding: 0px;
}
.width-small,
.width-default,
.width-wide,
.width-full {
  width: 100%;
  padding: 15px;
}
@media (max-width: 575px) {
  .width-small,
  .width-default,
  .width-wide,
  .width-full {
    padding: 0;
  }
}
.breadcrumb-area {
  background-color: white;
  width: 100%;
}
.width-fit-content {
  width: min-content;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper.min-max-input {
  width: 100px;
  text-align: right;
}
.sub-inner-card {
  background-color: #fcfcfc !important;
}
.ant-card.card-success {
  border-color: #b7eb8f;
}
.ant-card.card-success .ant-card-head {
  background-color: #f6ffed;
  border-color: #b7eb8f;
}
.ant-card.card-warning {
  border-color: #ffed7a;
}
.ant-card.card-warning .ant-card-head {
  background-color: #fffde6;
  border-color: #ffed7a;
}
.ant-card.card-danger {
  border-color: #ffccc7;
}
.ant-card.card-danger .ant-card-head {
  background-color: #fff2f0;
  border-color: #ffccc7;
}
.header-icon {
  color: white;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
}
.header-icon svg {
  width: 18px;
  height: 18px;
}
.ant-page-header.subHeader {
  padding: 0%;
  background-color: lightgray;
}
.ant-page-header-heading-sub-title {
  color: rgba(0, 0, 0, 0.66) !important;
}
.ant-input-prefix {
  margin-right: 10px !important;
}
.ant-select {
  width: 100%;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  align-self: start;
  margin-top: 2px;
}
.ant-form-item-extra {
  color: rgba(0, 0, 0, 0.66) !important;
}
.align-center {
  align-items: center;
  text-align: center;
  align-self: center;
  align-content: center;
}
.antd_steps_nested {
  margin-top: 15px !important;
}
.ant-input-prefix,
.ant-input-suffix {
  color: #A5A5A5;
}
td > .ant-form-item {
  margin-bottom: 0px;
}
.btn-left-align {
  width: 100%;
  text-align: left;
}
.ant-btn-link-light {
  color: White;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.btn-white-link {
  color: White;
}
.btn-white-link :hover {
  color: #2A5192;
}
.ant-btn-dangerous {
  color: #EB0004;
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary {
  background: #EB0004 !important;
  border-color: #EB0004 !important;
}
.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab.customDraggableTab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab.customDraggableTab {
  display: flex;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  display: flex;
}
.ant-form-item.description-container {
  margin-bottom: 0px;
}
.footer-space {
  float: right;
}
.bf-container {
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
}
.table-span-light {
  width: 15px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -10px;
  margin-bottom: -10px;
  background-color: #A5A5A5;
}
.table-span-dark {
  width: 15px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -10px;
  margin-bottom: -10px;
  background-color: #2A5192;
}
.table-row-light {
  background-color: #A5A5A5;
}
.table-row-dark {
  background-color: #3C3C3C;
}
.table-row-primary {
  background-color: #002A4B;
}
.table-row-secondary {
  background-color: #2A5192;
}
.table-row-primary-light {
  background-color: #E8EFFC;
}
.table-half-size {
  width: 50%;
}
@media (max-width: 991.98px) {
  .column {
    flex: 50% !important;
    /* !important so that it overrides the inline style because by default it has higher priority */
  }
}
@media (max-width: 767.98px) {
  .header-title {
    display: none;
  }
  .content-main,
  .content-pad {
    padding: 12px;
  }
  .content-head {
    padding: 12px 15px;
  }
  .content-container {
    padding: 12px;
  }
  .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 0 12px 12px;
  }
  .ant-layout-sider.ant-layout-sider-collapsed {
    min-width: 0 !important;
    max-width: 0 !important;
    width: 0 !important;
    flex: 0 !important;
  }
  .ant-layout-sider.ant-layout-sider-collapsed li.ant-menu-item,
  .ant-layout-sider.ant-layout-sider-collapsed li.ant-menu-submenu {
    display: none;
  }
  .ant-modal {
    width: 95% !important;
  }
  .layout-fullscreen > .ant-layout-sider > .ant-layout-sider-children > .logo-container {
    width: 195px;
  }
  .layout-fullscreen > .ant-layout-sider > .ant-layout-sider-children .scrollbar-sidenav {
    top: 48px !important;
  }
  .layout-fullscreen > .ant-layout-sider > .ant-layout-sider-children > .ant-menu {
    top: 48px;
  }
  .layout-fullscreen > .ant-layout > .ant-layout-header {
    left: 195px;
  }
  .layout-fullscreen > .ant-layout > .ant-layout-content.page-content {
    left: calc(195px + 12px);
    right: 12px;
    top: calc(48px + 12px);
    bottom: 12px;
  }
  .layout-fullscreen > .ant-layout > .ant-layout-content.page-content > .ant-layout.content-container > .ant-layout-content,
  .layout-fullscreen > .ant-layout > .ant-layout-content.page-content > .ant-layout.content-container > .ant-spin-nested-loading > .ant-spin-container > .ant-layout-content {
    max-height: calc(100vh - 105px + 17px + 10px);
  }
  .layout-fullscreen > .ant-layout-sider-collapsed + .ant-layout > .ant-layout-header {
    left: 0;
  }
  .layout-fullscreen > .ant-layout-sider-collapsed + .ant-layout > .ant-layout-content {
    left: 12px;
  }
  .column {
    flex: 100% !important;
    /* !important so that it overrides the inline style because by default it has higher priority */
  }
}
@media (max-width: 605px) {
  .content-main,
  .content-pad {
    padding: 6px;
  }
  .content-head {
    padding: 6px 8px;
  }
  .content-container {
    padding: 12px 6px 6px 6px;
  }
  .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 0 6px 6px;
  }
  .column {
    flex: 100% !important;
    /* !important so that it overrides the inline style because by default it has higher priority */
  }
}
.academic-year-overview {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
